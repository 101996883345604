import { useState } from 'react';
import {
  ScaleIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/outline';
import {
  CashIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import { getExpenses } from "../lib/backend";
import { useQuery } from 'react-query';
import { subscriptionIsValid } from '../lib/util';

const statusStyles = {
  paid: 'bg-green-100 text-green-800',
  requested: 'bg-yellow-100 text-yellow-800',
  new: 'bg-blue-100 text-blue-800',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const navigate = useNavigate();
  const { user } = useOutletContext()

  const [currentPage, setCurrentPage] = useState(1)
  const limit = 10;
  const totalPages = Math.ceil(user.expensesCount / limit)
  const [tokens, setTokens] = useState({
    "1": null
  })

  const {
    data
  } = useQuery(['projects', currentPage], () => fetchExpenses(limit, currentPage), { keepPreviousData: true, initialData: [] })

  const fetchExpenses = async (limit, page) => {
    const { expenses, nextToken } = await getExpenses(limit, tokens[page])
    setTokens({ ...tokens, [currentPage + 1]: nextToken })

    return expenses
  }

  return (
    <main className="flex-1 pb-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl font-semibold text-gray-900">Overview</h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <ScaleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Health Expenses</dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">${user.totalCost}</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <CurrencyDollarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Available to Withdraw</dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">${user.totalAvailable}</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto sm:flex sm:items-center sm:px-6 lg:px-8 mt-8 mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Expenses</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all your most recent medical expenses.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            disabled={user.expensesCount >= 30 && !subscriptionIsValid(user)}
            onClick={() => navigate('/expenses/new')}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none sm:w-auto"
          >
            Add expense
          </button>
        </div>
      </div>

      {data.length ?
        <>
          {/* Activity list (smallest breakpoint only) */}
          <div className="shadow sm:hidden">
            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
              {data.map((transaction) => (
                <li key={transaction.id}>
                  <Link to={`/expenses/${transaction.id}`} state={transaction} className="block px-4 py-4 bg-white hover:bg-gray-50">
                    <span className="flex items-center space-x-4">
                      <span className="flex-1 flex space-x-2 truncate">
                        <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="flex flex-col text-gray-500 text-sm truncate">
                          <span className="truncate">{transaction.vendor} - {transaction.serviceType}</span>
                          <span>
                            <span className="text-gray-900 font-medium">${transaction.claimAmount}</span>
                          </span>
                          <time dateTime={transaction.createdAt}>{transaction.dateOfService}</time>
                        </span>
                      </span>
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex-1 flex justify-between">
                <button
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                >
                  Previous
                </button>
                <button
                  disabled={!(currentPage < totalPages)}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                >
                  Next
                </button>
              </div>
            </nav>
          </div>

          {/* Activity table (small breakpoint and up) */}
          <div className="hidden sm:block">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Transaction
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Amount
                        </th>
                        <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                          Status
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((transaction) => (
                        <tr key={transaction.id} className="bg-white">
                          <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            <div className="flex">
                              <Link to={`/expenses/${transaction.id}`} state={transaction} className="group inline-flex space-x-2 truncate text-sm">
                                <CashIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <p className="text-gray-500 truncate group-hover:text-gray-900">
                                  {transaction.vendor} - {transaction.serviceType}
                                </p>
                              </Link>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                            <span className="text-gray-900 font-medium">${transaction.claimAmount} </span>
                          </td>
                          <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                            {transaction.paid ? (
                              <span
                                className={classNames(
                                  statusStyles['paid'],
                                  'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                )}
                              >
                                Reimbursed
                              </span>
                            ) : (
                              <span
                                className={classNames(
                                  statusStyles['new'],
                                  'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                )}
                              >
                                Not Reimbursed
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                            <time dateTime={transaction.createdAt}>{transaction.dateOfService}</time>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Pagination */}
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{currentPage === 1 ? 1 : (currentPage - 1) * limit}</span> to <span className="font-medium">{currentPage !== totalPages ? currentPage * limit : user.expensesCount}</span> of{' '}
                        <span className="font-medium">{user.expensesCount}</span> results
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <button
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                      >
                        Previous
                      </button>
                      <button
                        disabled={!(currentPage < totalPages)}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                      >
                        Next
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No expenses</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new expense.</p>
        </div>
      }
    </main>
  );
}