const subscriptionIsValid = user => {
  if (user.subscriptionInterval && user.currentPeriodEnd && new Date().toJSON() < user.currentPeriodEnd) {
    return true
  } else {
    return false
  }
}

export {
  subscriptionIsValid
}