import { ExclamationIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Alert({ type, title, message }) {
  let warning, error, success
  warning = error = success = false

  switch (type) {
    case 'warning':
      warning = true
      break;
    case 'error':
      error = true
      break;
    case 'success':
    default:
      success = true
      break;
  }

  return (
    <div className={classNames("rounded-md p-4", warning && 'bg-yellow-50', error && 'bg-red-50', success && 'bg-green-50')}>
      <div className="flex">
        <div className="flex-shrink-0">
          {warning && <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />}
          {error && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
          {success && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3 className={classNames("text-sm font-medium", warning && 'text-yellow-800', error && 'text-red-800', success && 'text-green-800')}>{title}</h3>
          {message && (
            <div className={classNames("mt-2 text-sm", warning && 'text-yellow-700', error && 'text-red-700', success && 'text-green-700')}>
              <p>
                {message}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}