import { API } from 'aws-amplify';
import gql from 'graphql-tag';

const getMyProfile = async () => {
  const result = await API.graphql({
    query: gql`
      query getMyProfile {
        getMyProfile {
          birthdate
          createdAt
          documentsCount
          expensesCount
          firstName
          id
          imageUrl
          lastName
          location
          totalCost
          totalAvailable
          subscriptionInterval
          currentPeriodEnd
          email
        }
      }
    `,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.getMyProfile
}

const getExpenses = async (limit, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getExpenses($limit: Int!, $nextToken: String) {
        getExpenses(limit: $limit, nextToken: $nextToken) {
          nextToken
          expenses {
            id
            createdAt
            notes
            dateOfService
            claimAmount
            paid
            serviceType
            vendor
            serviceFor
            documents {
              id
              documentUrl
              name
              fileId
            }
          }
        }
      }
    `,
    variables: {
      limit,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.getExpenses
}

const getImageUploadUrl = async (extension, contentType) => {
  const result = await API.graphql({
    query: gql`
      query getImageUploadUrl($extension:String, $contentType:String) {
        getImageUploadUrl(extension:$extension, contentType:$contentType) 
      }
    `,
    variables: {
      extension,
      contentType
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.getImageUploadUrl
}

const editMyProfile = async (newProfile) => {
  const result = await API.graphql({
    query: gql`
      mutation editMyProfile($newProfile: ProfileInput!) {
        editMyProfile(newProfile: $newProfile) {
          birthdate
          createdAt
          documentsCount
          expensesCount
          firstName
          id
          imageUrl
          lastName
          location
        }
      }
    `,
    variables: {
      newProfile
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  return result.data.editMyProfile
}

const expense = async (input) => {
  const result = await API.graphql({
    query: gql`
      mutation expense($input: ExpenseInput!) {
        expense(newExpense: $input) {
          id
          profile {
            id
            firstName
            lastName
          }
          createdAt
          notes
          dateOfService
          claimAmount
          paid
          serviceType
          vendor
          serviceFor
          documents {
            id
            documentUrl
            fileId
          }
        }
      }
    `,
    variables: {
      input
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  return result.data.expense
}

const addDocument = async (expenseId, name, fileId) => {
  const result = await API.graphql({
    query: gql`
      mutation addDocument($expenseId: ID!, $name: String!, $documentUrl: AWSURL, $fileId: String) {
        addDocument(expenseId: $expenseId, name: $name, documentUrl: $documentUrl, fileId: $fileId)
      }
    `,
    variables: {
      expenseId,
      name,
      fileId
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.addDocument
}

const pay = async (expenseId, amount) => {
  const result = await API.graphql({
    query: gql`
      mutation pay($expenseId: ID!, $amount: Float!) {
        pay(expenseId: $expenseId, amount: $amount)
      }
    `,
    variables: {
      expenseId,
      amount
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.pay
}

const unPay = async (expenseId, amount) => {
  const result = await API.graphql({
    query: gql`
      mutation unPay($expenseId: ID!, $amount: Float!) {
        unPay(expenseId: $expenseId, amount: $amount)
      }
    `,
    variables: {
      expenseId,
      amount
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.unPay
}

const search = async (query, limit, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query search($query: String!, $limit: Int!, $nextToken: String) {
        search(query: $query, limit: $limit, nextToken: $nextToken) {
          nextToken
          expenses {
            id
            createdAt
            notes
            dateOfService
            claimAmount
            paid
            serviceType
            vendor
            serviceFor
            documents {
              id
              documentUrl
              name
              fileId
            }
          }
        }
      }
    `,
    variables: {
      query,
      limit,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.search
}

const getCheckoutUrl = async (priceLookupKey) => {
  const result = await API.graphql({
    query: gql`
      query getCheckoutUrl($priceLookupKey: String!) {
        getCheckoutUrl(priceLookupKey: $priceLookupKey)
      }
    `,
    variables: {
      priceLookupKey
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.getCheckoutUrl
}

const getBillingPortalUrl = async () => {
  const result = await API.graphql({
    query: gql`
      query getBillingPortalUrl {
        getBillingPortalUrl
      }
    `,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })

  return result.data.getBillingPortalUrl
}

export {
  getMyProfile,
  getExpenses,
  getImageUploadUrl,
  editMyProfile,
  expense,
  addDocument,
  pay,
  unPay,
  search,
  getCheckoutUrl,
  getBillingPortalUrl
}