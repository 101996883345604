import { useEffect, useRef, useState } from "react"
import { getImageUploadUrl, editMyProfile, getCheckoutUrl, getBillingPortalUrl } from "../lib/backend"
import { useForm } from 'react-hook-form';
import http from 'axios';
import { useOutletContext } from "react-router-dom";
import PricingCard from "../components/PriceCard";
import CurrentPlanCard from "../components/CurrentPlanCard";

export default function Profile() {
  const { user } = useOutletContext();
  const { register, handleSubmit, reset, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      location: '',
      birthdate: '',
      imageUrl: ''
    }
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      location: user.location,
      birthdate: user.birthdate,
      imageUrl: user.imageUrl
    })
  }, [reset, user])

  const inputFile = useRef(null)

  const handlePictureSelected = async event => {
    if (!event.target.files.length) {
      return
    }
    const picture = event.target.files[0]
    const url = await getImageUploadUrl(picture.name.split('.')[1], picture.type)

    await http({
      method: 'put',
      url,
      headers: {
        'Content-Type': picture.type
      },
      data: picture
    })

    setValue("imageUrl", url.split('?')[0])
  }

  const onSubmit = async data => {
    setLoading(true)
    if (!data.imageUrl) {
      delete data.imageUrl
    }

    if (!data.birthdate) {
      delete data.birthdate

    }

    await editMyProfile(data)
    setLoading(false)
  }

  const onCheckout = async lookUpKey => {
    const url = await getCheckoutUrl(lookUpKey)
    window.location.replace(url)
  }

  const onBillingPortal = async () => {
    const url = await getBillingPortalUrl()
    window.location.replace(url)
  }

  const getUsage = () => {
    return `${Math.round((user.expensesCount / 30) * 100).toFixed(0)}%`
  }

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
              <p className="mt-1 text-sm text-gray-600">Your profile details.</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        {...register("firstName", { required: true })}
                      />
                      {errors.firstName && <span>This field is required</span>}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        {...register("lastName", { required: true })}
                      />
                      {errors.lastName && <span>This field is required</span>}
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        autoComplete="location"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        {...register("location")}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        Birthdate
                      </label>
                      <input
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        {...register("birthdate")}
                      />
                    </div>

                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">Profile Picture</label>
                      <div className="mt-1 flex items-center">
                        {watch("imageUrl") ? (
                          <img className="inline-block h-12 w-12 rounded-full overflow-hidden" src={watch("imageUrl")} alt="" />
                        ) : (
                          <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={inputFile}
                          onChange={handlePictureSelected}
                        />
                        <input style={{ display: 'none' }} {...register("imageUrl")} />
                        <button
                          type="button"
                          className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => inputFile.current.click()}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {loading && (
                      <svg role="status" className="inline mr-3 w-4 h-4 text-black animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                      </svg>
                    )}
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Plan and Billing</h3>
              <p className="mt-1 text-sm text-gray-600">All transactions are handled by Stripe.</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              {!user.subscriptionInterval && (
                <div className="px-4 py-5 pb-5 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Free Plan Usage</h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    Free Plan includes 30 expenses for free for forever.
                  </p>
                  <div className="w-full bg-gray-200 rounded-full mt-3">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full rounded-r-full" style={{ width: getUsage() }}>{user.expensesCount}</div>
                  </div>
                </div>
              )}
              {user.subscriptionInterval ? <>
                {user.subscriptionInterval === 'month' && (
                  <CurrentPlanCard planDescription="You are currently subscribed to the Standard Monthly Plan." currentPeriodEnd={user.currentPeriodEnd} onClick={onBillingPortal} />
                )}
                {user.subscriptionInterval === 'year' && (
                  <CurrentPlanCard planDescription="You are currently subscribed to the Standard Yearly Plan." currentPeriodEnd={user.currentPeriodEnd} onClick={onBillingPortal} />
                )}
              </>
                : (
                  <div className="px-4 py-5 bg-white sm:p-6 flex flex-col justify-around md:flex-row">
                    <PricingCard name="Standard Monthly" frequency="month" price="0.99" onClick={async () => await onCheckout('standard_monthly')} />
                    <PricingCard name="Standard Yearly" frequency="year" price="11.00" onClick={async () => await onCheckout('standard_yearly')} />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Support</h3>
              <p className="mt-1 text-sm text-gray-600">Questions? We are here to help!</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <p>
                  Please <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="mailto:support@hsabutler.com">email us</a> for any support questions or feature enhancement. We will reply promptly!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
