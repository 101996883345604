import { useTheme, Image, View, Text, Heading, useAuthenticator, Button, Theme } from '@aws-amplify/ui-react';

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="/logo.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral['80']}`}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Forgot Password?
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  }
};

const formFields = {
  signUp: {
    email: {
      //   labelHidden: false,
      label: 'Email:',
      placeholder: 'Enter your email',
      isRequired: true,
      order: 1,
    },
    password: {
      placeholder: 'Enter your Password',
      isRequired: true,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      isRequired: true,
      order: 3,
    },
    given_name: {
      label: 'First Name',
      placeholder: 'Enter your First Name',
      isRequired: true,
      order: 4,
    },
    family_name: {
      label: 'Last Name',
      placeholder: 'Enter your Last Name',
      isRequired: true,
      order: 5,
    },
  }
};

const theme = {
  name: 'hsabutler-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: '-apple-system, BlinkMacSystemFont, helvetica, sans-serif' },
        static: { value: '-apple-system, BlinkMacSystemFont, helvetica, sans-serif' },
      },
    },
    colors: {
      teal: {
        10: { value: "#eef2ff" },
        20: { value: "#e0e7ff" },
        30: { value: "#c7d2fe" },
        40: { value: "#a5b4fc" },
        50: { value: "#818cf8" },
        60: { value: "#6366f1" },
        70: { value: "#4f46e5" },
        80: { value: "#4338ca" },
        90: { value: "#3730a3" },
        100: { value: "#312e81" },
      }
    },
  },
};

export {
  components,
  formFields,
  theme
}