import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Reports from './routes/Reports';
import Expense from './routes/Expense';
import Profile from './routes/Profile';
import Dashboard from './routes/Dashboard';
import NewExpense from './routes/NewExpense';
import { Amplify } from 'aws-amplify';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@aws-amplify/ui-react/styles.css';
import { components, formFields, theme } from './components/auth/amplify-customizations';


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_REGION,
    }
  }
});

const appConfig = {
  'aws_appsync_graphqlEndpoint': process.env.REACT_APP_API_URL,
  'aws_appsync_region': process.env.REACT_APP_AWS_REGION,
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
};

Amplify.configure(appConfig)

const queryClient = new QueryClient()

ReactDOM.render(
  <AmplifyProvider theme={theme}>
    <Authenticator
      loginMechanisms={['email']}
      signUpAttributes={['email', 'family_name', 'given_name']}
      className="flex justify-center items-center h-screen"
      components={components}
      formFields={formFields}
    >
      {({ signOut, user }) => (
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<App user={user} signOut={signOut} />} >
                <Route index element={<Dashboard />} />
                <Route path='expenses'>
                  <Route path=':expenseId' element={<Expense />} />
                  <Route path='new' element={<NewExpense />} />
                </Route>
                <Route path='reports' element={<Reports />} />
                <Route path='profile' element={<Profile />} />

                <Route
                  path='*'
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      )}
    </Authenticator>
  </AmplifyProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
