import { useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { search } from '../lib/backend'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Search() {
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const { isFetching, data } = useQuery(['search', query], () => search(query, 10), {
    initialData: {
      expenses: []
    },
    enabled: !!query,

  })

  const onInput = (event) => {
    setQuery(event.target.value)
  }

  const onBlur = (event) => {
    if (!event.relatedTarget) {
      setQuery('')
    }
  }

  const onSelect = (expense) => {
    console.log('onSelect')
    navigate(`/expenses/${expense.id}`, { state: expense })
    setQuery('')
  }

  return (
    <div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
      <Combobox
        as="div"
        onChange={onSelect}
      >
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <Combobox.Input
            className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
            placeholder="Search..."
            onChange={onInput}
            autoComplete="off"
            onBlur={onBlur}
          />
        </div>

        {data.expenses.length > 0 && !isFetching && (
          <Combobox.Options static className="absolute max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 w-full max-w-xs transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            {data.expenses.map((expense) => (
              <Combobox.Option
                key={expense.id}
                value={expense}
                className={({ active }) =>
                  classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                }
              >
                {expense.vendor} - {expense.serviceType}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {isFetching && (
          <p className="cursor-default select-none px-4 absolute max-h-72 scroll overflow-y-auto py-2 text-sm text-gray-800 w-full max-w-xs transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">Loading...</p>
        )}
        {query !== '' && !isFetching && data.expenses.length === 0 && (
          <p className="cursor-default select-none px-4 absolute max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 w-full max-w-xs transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">No expenses found.</p>
        )}
      </Combobox>
    </div>
  )
}